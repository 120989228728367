import logo from "./assets/img/logo.png";
import section1Img from "./assets/img/section-1.png";
import section1MbImg from "./assets/img/section-1-mb.png";
import aboutLeft from "./assets/img/about-left.png";
import aboutRight from "./assets/img/about-right.png";
import aboutBg from "./assets/img/bg-about.png";
import step1 from "./assets/img/step-1.png";
import step2 from "./assets/img/step-2.png";
import step3 from "./assets/img/step-3.png";
import doge from "./assets/img/doge.png";
import dogeFooter from "./assets/img/footer.png";
import arrow from "./assets/img/arrow.png";
import about1 from "./assets/img/about-1.png";
import about2 from "./assets/img/about-2.png";
import about3 from "./assets/img/about-3.png";
import about4 from "./assets/img/about-4.png";

import AOS from "aos";
import "aos/dist/aos.css";

import "./App.css";
import { useEffect, useState } from "react";

function App() {
  useEffect(() => {
    AOS.init({
      duration: 800,
    });
    AOS.refresh();
  }, []);

  const [isMobile, setIsMobile] = useState(false);

  //choose the screen size
  const handleResize = () => {
    if (window.innerWidth < 720) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  // create an event listener
  useEffect(() => {
    window.addEventListener("resize", handleResize);
  });

  return (
    <div className="App">
      <header className="bg-black py-5 lg:py-4">
        <div className="container mx-auto px-2 lg:px-5 flex flex-col gap-5 lg:flex-row justify-between items-center">
          <div className="max-w-52">
            <img src={logo} alt="" />
          </div>
          <ul className="flex gap-4 lg:gap-10 items-center text-white text-xl">
            <li>
              <a className="transition-all hover:opacity-75">Home</a>
            </li>
            <li>
              <a className="transition-all hover:opacity-75">Trade</a>
            </li>
            <li>
              <a className="transition-all hover:opacity-75">Earn</a>
            </li>
            <li>
              <a className="transition-all hover:opacity-75">Bridge</a>
            </li>
            <li>
              <a className="transition-all hover:opacity-75">Launchpad</a>
            </li>
          </ul>
          <a className="font-title inline-flex items-center justify-center text-center bg-primary px-8 py-2.5 text-lg transition-all hover:opacity-75">
            Open App
          </a>
        </div>
      </header>
      <main>
        <section>
          <img src={isMobile ? section1MbImg : section1Img} alt="" />
        </section>

        <section className="py-12 lg:py-16 relative" data-aos="fade-up">
          <div className="max-w-2xl absolute left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2">
            <img src={aboutBg} alt="" />
          </div>
          <div className="hidden lg:block max-w-md absolute right-0 top-1/2 -translate-y-1/2">
            <img src={aboutRight} alt="" />
          </div>
          <div className="relative container mx-auto px-2 lg:px-5">
            <div className="max-w-3xl">
              <h2 className="text-4xl font-bold">
                What is <span className="font-normal">DBRIDGE Ecosystem?</span>
              </h2>
              <p className="mt-3 mb-6">
                A complete ecosystem that allows users to experience one-click
                conveniences while accessing decentralized protocols built in a
                closed ecosystem developed by a team of experienced developers.
                <br />A multi-chain, multi-layer asset bridge that provides
                instant transfers. Decentralized and non-custodial asset bridge
                that supports token transfer across blockchains and layer-2
                rollup.
              </p>
              <a className="font-title inline-flex items-center justify-center text-center bg-primary px-8 py-2.5 text-lg transition-all hover:opacity-75">
                LEARN MORE
              </a>
            </div>
          </div>
          <div className="relative max-w-lg">
            <img src={aboutLeft} alt="" />
          </div>
        </section>

        <section className="py-10" data-aos="fade-up">
          <div className="container mx-auto px-2 lg:px-5">
            <h2 className="text-4xl font-bold">Getting started</h2>
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-7 lg:gap-10 mt-8">
              <div className="bg-primary px-8 pt-6 pb-2" data-aos="flip-down">
                <div className="text-xl flex items-center justify-between gap-5 font-bold font-title">
                  <span>Connect Wallet</span>
                  <span className="text-white">01</span>
                </div>
                <p className="text-lg mt-3">
                  A wallets is necessary for people wanting to use, trade, or
                  hold Dogecoin. You can pick a wallet and connect to dBridge.
                </p>
                <div className="max-w-40 mx-auto mt-4">
                  <img src={step1} alt="" />
                </div>
              </div>
              <div className="bg-primary px-8 pt-6 pb-2" data-aos="flip-down">
                <div className="text-xl flex items-center justify-between gap-5 font-bold font-title">
                  <span>Bridge to Doge</span>
                  <span className="text-white">02</span>
                </div>
                <p className="text-lg mt-3">
                  You need to have some ETH in your wallet, enter the amount of
                  ETH you want to transfer to the bridge and confirm.
                </p>
                <div className="max-w-40 mx-auto mt-4">
                  <img src={step2} alt="" />
                </div>
              </div>
              <div className="bg-primary px-8 pt-6 pb-2" data-aos="flip-down">
                <div className="text-xl flex items-center justify-between gap-5 font-bold font-title">
                  <span>Wait a sec</span>
                  <span className="text-white">03</span>
                </div>
                <p className="text-lg mt-3">
                  Process will take some time to calculate and convert. Doge
                  will be transferred to your wallet upon completion.
                </p>
                <div className="max-w-40 mx-auto mt-4">
                  <img src={step3} alt="" />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="py-12 lg:py-16" data-aos="fade-up-right">
          <div className="container mx-auto px-2 lg:px-5">
            <img src={doge} alt="" />
          </div>
        </section>

        <section className="py-12 lg:py-16">
          <div className="container mx-auto px-2 lg:px-5">
            <h2 className="text-4xl font-bold text-center">
              Find out more about
            </h2>
            <h1 className="mt-2 text-7xl font-bold text-center">DBRIDGE</h1>

            <div className="grid grid-cols-1 lg:grid-cols-4 gap-5 mt-20">
              <a
                className="bg-primary px-8 pt-6 pb-5"
                data-aos="fade-right"
                data-aos-delay="100"
              >
                <div className="text-lg flex items-center justify-between gap-5 font-bold font-title">
                  <span>Documentation</span>
                  <div className="w-4 h-4">
                    <img src={arrow} />
                  </div>
                </div>
                <p className="text-xl mt-5">
                  Learn more about dBrigde Ecosystem, future features and
                  developers team.
                </p>
                <div className="max-w-16 mx-auto mt-6">
                  <img src={about1} alt="" />
                </div>
              </a>
              <a
                className="bg-primary px-8 pt-6 pb-5"
                data-aos="fade-right"
                data-aos-delay="200"
              >
                <div className="text-lg flex items-center justify-between gap-5 font-bold font-title">
                  <span>Blogs</span>
                  <div className="w-4 h-4">
                    <img src={arrow} />
                  </div>
                </div>
                <p className="text-xl mt-5">
                  Stay up to date with news, blog posts and updates from
                  developer team.
                </p>
                <div className="max-w-16 mx-auto mt-6">
                  <img src={about2} alt="" />
                </div>
              </a>
              <a
                className="bg-primary px-8 pt-6 pb-5"
                data-aos="fade-right"
                data-aos-delay="300"
              >
                <div className="text-lg flex items-center justify-between gap-5 font-bold font-title">
                  <span>Announcement</span>
                  <div className="w-4 h-4">
                    <img src={arrow} />
                  </div>
                </div>
                <p className="text-xl mt-5">
                  Announcements form the dBridge developer team can be found
                  here.
                </p>
                <div className="max-w-16 mx-auto mt-6">
                  <img src={about3} alt="" />
                </div>
              </a>
              <a
                className="bg-primary px-8 pt-6 pb-5"
                data-aos="fade-right"
                data-aos-delay="400"
              >
                <div className="text-lg flex items-center justify-between gap-5 font-bold font-title">
                  <span>FAQs</span>
                  <div className="w-4 h-4">
                    <img src={arrow} />
                  </div>
                </div>
                <p className="text-xl mt-5">
                  Read our responses to some frequently asked questions about
                  dBridge.
                </p>
                <div className="max-w-16 mx-auto mt-6">
                  <img src={about4} alt="" />
                </div>
              </a>
            </div>
          </div>
        </section>
      </main>

      <footer className="bg-footer pt-8 mt-5 lg:mt-12">
        <div className="container mx-auto px-2 lg:px-5 flex flex-col lg:flex-row justify-between gap-5">
          <div className="max-w-xl">
            <div className="mx-auto lg:mx-0 max-w-64">
              <img src={logo} alt="" />
            </div>
            <p className="text-center lg:text-left my-4">
              Decentralized and non-custodial asset bridge that supports token
              transfer across blockchains and layer-2 rollup.
            </p>
            <div className="max-w-64 mx-auto -mt-20">
              <img src={dogeFooter} alt="" />
            </div>
          </div>
          <div className="text-lg flex gap-5 flex-col items-center lg:items-end justify-between">
            <div className="flex gap-24">
              <div className="text-right">
                <h4 className="font-title font-bold">Social</h4>
                <ul className="mt-3 flex flex-col gap-2 ">
                  <li>
                    <a
                      href="https://t.me/DbridgeToken"
                      className="transition-all hover:opacity-50"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Telegram
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://twitter.com/DbridgeToken"
                      className="transition-all hover:opacity-50"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Twitter
                    </a>
                  </li>
                  <li>
                    <a className="transition-all hover:opacity-50">Discord</a>
                  </li>
                  <li>
                    <a className="transition-all hover:opacity-50">Medium</a>
                  </li>
                </ul>
              </div>
              <div className="text-right">
                <h4 className="font-title font-bold">Ecosystem</h4>
                <ul className="mt-3 flex flex-col gap-2 ">
                  <li>
                    <a className="transition-all hover:opacity-50">Trade</a>
                  </li>
                  <li>
                    <a className="transition-all hover:opacity-50">Earn</a>
                  </li>
                  <li>
                    <a className="transition-all hover:opacity-50">Bridge</a>
                  </li>
                  <li>
                    <a className="transition-all hover:opacity-50">Launchpad</a>
                  </li>
                </ul>
              </div>
            </div>
            <p className="text-center lg:text-left pb-4">
              @2023 | copyright © dBridge team | All rights reserved
            </p>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default App;
